import React from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import { Container, Divider } from "semantic-ui-react";

import Footer from "./Footer";
import Header from "./Header";
import About from "./About";
import MainLogo from "./MainLogo";

function App() {
  return (
    <Container>
      <HashRouter>
        <Header />
        <Divider fitted />
        <Routes>
          <Route path="/" exact element={<MainLogo />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </HashRouter>
      <Footer />
    </Container>
  );
}

export default App;
